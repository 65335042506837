import React, { useState, useEffect, useRef } from "react";

export default function PlayVideo() {
  const videoRef = useRef(null);
  const audioRef = useRef(null);

  const [mediaPlaying, setMediaPlaying] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const video = videoRef.current;
      const audio = audioRef.current;

      if (video && audio) {
        const videoRect = video.getBoundingClientRect();

        // Check if video is completely out of the viewport
        if (videoRect.bottom < 0 || videoRect.top > window.innerHeight) {
          if (mediaPlaying) {
            video.pause();
            audio.play();
            setMediaPlaying(false);
          }
        } else if (!mediaPlaying) {
          video.play();
          audio.pause();
          setMediaPlaying(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [mediaPlaying]);

  const handlePlay = () => {
    videoRef.current.play();
    audioRef.current.pause();
    setIsPlaying(true);
  };

  /* TODO: Add Captions if available
  		<track
          src="/captions.vtt"
          kind="captions"
          srcLang="en"
          label="English"
          default
        />
   */
  return (
    <div className="autoplay-container">
      <video
        className="video"
        ref={videoRef}
        autoPlay
        loop
        style={{ width: "80%", height: "70%" }}
      >
        <source src="/video.mp4" type="video/mp4" />
      </video>
      <audio ref={audioRef} autoPlay loop>
        <source src="/audio.mp3" type="audio/mpeg" />
      </audio>
      {!mediaPlaying && (
        <div className="overlay" onClick={handlePlay}>
          <button>Play</button>
        </div>
      )}
    </div>
  );
}
